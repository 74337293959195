import * as React from 'react';

import SelectInput, { SelectInputProps } from './select-input';

export enum Industry {
  Consumer = 'Consumer',
  Energy = 'Energy, Resources & Industrials',
  Financial = 'Financial Services',
  Government = 'Government & Public Services',
  Life = 'Life Sciences & Health Care',
  Technology = 'Technology, Media & Telecommunications',
  Other = 'Other',
}

export interface IndustryInputProps extends Omit<SelectInputProps, 'name'> {}

export default function IndustryInput(props: IndustryInputProps) {
  return (
    <SelectInput
      id="industry"
      name="industry"
      label="Industry"
      data-name="industry"
      className="form-industry"
      {...props}
    >
      <option disabled value="">
        Select industry...
      </option>
      {Object.values(Industry).map((industry) => (
        <option key={industry} value={industry}>
          {industry}
        </option>
      ))}
    </SelectInput>
  );
}
