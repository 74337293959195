import * as React from 'react';
import { useField } from 'formik';

import ErrorMessage from './error-message';

export interface FileInputProps
  extends React.ComponentPropsWithoutRef<'input'> {
  name: string;
  label?: React.ReactNode;
}

export default function FileInput({
  id,
  name,
  label,
  disabled,
  className,
  ...props
}: FileInputProps) {
  const inputId = id || React.useId();
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const [field, meta, helper] = useField(name);
  const hasError = meta.touched && !!meta.error;

  const formatFileSize = () => {
    if (!field.value) return '';
    return Intl.NumberFormat().format(Math.round(field.value.size / 1024));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    helper.setTouched(true);
    helper.setValue(e.target.files[0]);
  };

  return (
    <div className={className}>
      {label && (
        <label htmlFor={inputId} className="form-label">
          {label}
        </label>
      )}
      <div className="file-input-row">
        <button
          type="button"
          disabled={disabled}
          className="button banner w-button"
          style={{ float: 'unset', margin: 0 }}
          onClick={() => fileInputRef.current?.click()}
        >
          Upload file
        </button>
        <p style={{ marginBottom: 0 }}>
          {field.value
            ? `${field.value.name} (${formatFileSize()}KB / 500KB)`
            : 'No file chosen (Format: PDF, Max. size: 500KB)'}
        </p>
        <input
          hidden
          type="file"
          id={inputId}
          name={name}
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleChange}
          {...props}
        />
      </div>
      {hasError && <ErrorMessage>{meta.error}</ErrorMessage>}
    </div>
  );
}
